<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 815.74 372.24"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M114.88,1.78q-11.07,0-18.44,3.69L13.17,55.53A30.35,30.35,0,0,0,3.69,65,22.24,22.24,0,0,0,0,77.14q0,9.48,7.38,17.65A23.22,23.22,0,0,0,25.29,103a24,24,0,0,0,13.18-3.69L84.84,71.34v274a24.39,24.39,0,0,0,7.38,17.92q7.36,7.38,19,7.38a27.51,27.51,0,0,0,19.5-7.38,23.6,23.6,0,0,0,7.9-17.92V27.07A25,25,0,0,0,132,9.42Q125.41,2.31,114.88,1.78Z"/><path d="M398.39,179.89a94.66,94.66,0,0,0,32.67-29.24q13.17-18.71,13.17-45.58A98.12,98.12,0,0,0,429.74,52.9,103.37,103.37,0,0,0,390,15.48a114.39,114.39,0,0,0-55.34-13.7q-30.56,0-55.59,13.7A105.83,105.83,0,0,0,239.24,52.9a96.77,96.77,0,0,0-14.75,52.17q0,25.82,12.64,45.05a93,93,0,0,0,32.15,30.3,120.06,120.06,0,0,0-38.73,35.05q-15,21.33-15,49.79a96.69,96.69,0,0,0,15.81,53.49q15.81,24.51,43.21,39t60.07,14.49q32.67,0,60.08-14.49t43.21-39a96.69,96.69,0,0,0,15.81-53.49q0-25.81-15-48.48T398.39,179.89Zm-107-115.4q16.86-16.33,43.21-16.34,25.81,0,42.69,16.34t16.86,41.63q0,24.78-17.39,41.37t-42.16,16.6q-25.29,0-42.68-16.6t-17.39-41.37Q274.55,80.83,291.41,64.49Zm92.49,244q-19.77,17.4-49.28,17.4t-49-17.4q-19.51-17.39-19.5-43.73t20-44.27q20-17.91,48.48-17.92t48.75,17.92q20.28,17.92,20.29,44.27T383.9,308.47Z"/><path d="M814.16,338,680.31,16.54a22.74,22.74,0,0,0-9-12.12A25.1,25.1,0,0,0,657.12.2a21.2,21.2,0,0,0-15,3.42,29.33,29.33,0,0,0-10.27,12.92L497.45,342.2a63.26,63.26,0,0,0-1.05,8.43q0,10,5.79,15.81t16.87,5.8a22.92,22.92,0,0,0,22.13-16.34l32.14-78H736.17l32.67,77.47a31.49,31.49,0,0,0,9.75,12.38A22.08,22.08,0,0,0,792,372.24,23.85,23.85,0,0,0,815.74,348,31.56,31.56,0,0,0,814.16,338ZM593.36,230,654.49,82.41,716.14,230Z"/></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'Icon18a',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
